// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Input
\* ------------------------------------------------------------------------ */

.input {
    & {
        appearance: none;
        background: $background;
        border: remify(1) solid darken($background, 20);
        border-radius: remify(5, 11.2);
        color: $foreground;
        display: block;
        font-family: $body-font;
        font-size: remify(11.2, 16);
        font-weight: 400;
        line-height: remify(18, 11.2);
        margin: remify(0 0 18, 11.2);
        outline: 0;
        padding: remify(9 11.2 5.5, 11.2);
        transition: border-color 0.15s, color 0.15s;
        width: 100%;
    }

    &:focus,
    &:hover {
        border-color: $primary_alt;
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .input {
        font-size: remify(14, 16);
    }
}

/* select variant */

.input.-select {
    & {
        cursor: pointer;
        // remove Firefox default styles & arrow
        text-indent: 0.01px;
        text-overflow: "";
        // remove IE default styles & arrow
        &::-ms-expand { display: none; }
    }

    &.-arrow {
        background-image: url("data:image/svg+xml;utf8,<svg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z'/></svg>");
        background-position: center right remify(8, 11.2);
        background-repeat: no-repeat;
        background-size: remify(11.2 11.2, 11.2);
        padding-right: remify(30, 11.2);
    }

    &.-translate {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23D9D9D9' d='M29.9,32c1.2,0,2.1-0.9,2.1-2.1V8.5c0-1.2-1-2.1-2.1-2.1H9.1L17.4,32H29.9z' /%3E%3Cpath fill='%234352B8' d='M 22.9,26 17.5,32 15.5,26z'/%3E%3Cpath fill='%23617D8B' d='M30,15.3V14h-6v-2h-2v2h-3.8v1.3h7.7c-0.4,1.4-1.3,2.8-2.2,3.9C22,17.3,22,16.7,22,16.7h-1.6c0,0,0.1,0.9,2.3,3.5c-0.7,0.7-1.3,1.2-1.3,1.2l0.5,1.6c0,0,0.8-0.6,1.7-1.7c0.9,1,2.2,2.3,3.7,3.7l1-1c-1.7-1.5-2.9-2.8-3.8-3.7c1.2-1.4,2.5-3.3,2.7-4.9L30,15.3L30,15.3z' /%3E%3Cpath fill='%234F88F5' d='M2.1,0C1,0,0,1,0,2.1v21.7C0,25,1,26,2.1,26h20.8L14.6,0H2.1z' /%3E%3Cpath fill='%23FFFFFF' d='M13.5,12.1H13h-1.8H9V14h2.6c-0.2,1.2-1.3,1.9-2.6,1.9c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9c0.7,0,1.3,0.2,1.8,0.6l1.4-1.4c-0.9-0.8-2-1.2-3.2-1.2c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9c2.4,0,4.7-1.8,4.7-4.9C13.6,12.7,13.6,12.4,13.5,12.1z' /%3E%3Cpath fill='%23FFFFFF' opacity='0.2' d='M17.1,6.4h12.7C31,6.4,32,7.4,32,8.5v12.8L17.1,6.4z' /%3E%3C/svg%3E%0A");
        background-position: right remify(11.2, 11.2) center;
        background-repeat: no-repeat;
        background-size: remify(22.4 22.4, 11.2);
        padding-right: remify(44.8, 11.2);
    }
}

/* checkbox & radiobutton variants */

.input.-checkbox,
.input.-radiobutton {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: remify(10, 11.2);
        cursor: pointer;
        display: inline-block;
        height: remify(16, 11.2);
        padding: 0;
        vertical-align: middle;
        width: remify(16, 11.2);
    }

    &.-radiobutton {
        border-radius: 50%;
    }

    &.-checkbox:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/%3E%3C/svg%3E");
    }

    &.-radiobutton:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z'/%3E%3C/svg%3E");
    }

    & + .text.-label {
        display: inline-block;
    }
}

/* touch checkbox & radiobutton variants */

@media (pointer: coarse) {
    .input.-checkbox,
    .input.-radiobutton {
        & {
            font-size: remify(24, 16);
        }
    }
}
