// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
        @include viewport-scale(16, strip-unit($xxl-break * 16), strip-unit($xs-break * 16));
    }
}

.hero_inner {
    & {
        background: $background_alt;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(10, $content-padding) relative-size(15, $content-padding));
    }
}

.hero_row {
    &.-padded {
        width: calc(100% + #{remify(relative-size(25, $column-gap), 16)});
    }

    &.-padded > .col,
    &.-padded > [class*="col-"] {
        padding-right: remify(relative-size(25, $column-gap), 16);
    }
}

.hero_title {
    & {
        background: $accent;
        border-radius: remify(5, 17);
        color: $light;
        font-size: remify(17, 16);
        font-weight: 400;
        margin: remify(7.5 0, 17);
        padding: remify(19 15 15, 17);
        text-align: center;
    }
}

.hero_menu-list_container {
    .menu-list.-quicklinks {
        @media screen and (max-width: $xs-break - (1 / 16)) {
            margin-bottom: remify(relative-size(15, $content-padding));
        }
    }
}

.hero_swiper-container {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        left: 50%;
        margin-bottom: remify(12, 16);
        margin-left: -50vw;
        margin-right: -50vw;
        position: relative;
        right: 50%;
        width: 100vw;
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        background: $background_alt;
        padding: remify(relative-size(10, $content-padding) relative-size(15, $content-padding));
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}
