// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-id, selector-no-qualifying-type, selector-no-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-bar_views {
    & {
        width: auto;
    }
}
