// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Overlay
\* ------------------------------------------------------------------------ */

.overlay {
    & {
        background: transparentize($dark, 0.5);
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 999;
    }
}
