// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * WP Page Builder
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-id, selector-no-qualifying-type, selector-no-type */

.wppb-container {
    .wppb-button-addon .wppb-btn-addons {
        color: $light;
    }

    .wppb-button-addon .wppb-btn-addons:hover {
        color: $light;
    }
}
