// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation_inner {
    & {
        border-bottom-width: remify(relative-size(15, $content-padding));
        padding: remify(0 relative-size(15, $content-padding));
    }
}

.navigation_menu-list_container {
    .menu-list.-navigation:not(.-vertical) {
        transform: translateX(#{remify(-25, 17)});
    }

    .menu-list.-social:not(.-vertical) {
        transform: translateX(#{remify(6, 20)});
    }
}

/* fullbleed variant */

.navigation-block.-fullbleed {
    & {
        border-bottom-width: remify(relative-size(15, $content-padding));
        padding: remify(0 relative-size(15, $content-padding));
    }

    > .navigation_inner {
        border-bottom: 0;
        padding: 0;
    }
}
