// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $accent;
        display: block;
        font-family: $heading-font;
        font-size: remify(22.5, 16);
        font-weight: 700;
        line-height: remify(27, 22.5);
        margin: remify(0 0 10, 22.5);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(28, 16);
        }
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: darken($background_alt, 10);
        content: "";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    &::before {
        margin-right: remify(16, 16);
    }

    &::after {
        margin-left: remify(16, 16);
    }
}
