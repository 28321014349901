// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(30, $content-padding) relative-size(20, $content-padding));
    }
}

.footer_row {
    & > [class*="col-"] {
        padding-top: remify(16);
    }

    & > [class*="col-"]:not(:last-child) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23C7C3BA' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: left bottom;
        background-repeat: repeat-x;
        background-size: remify(6);
        padding-bottom: remify(15);
    }
}

.footer_link {
    & {
        color: $foreground;
    }

    &:focus,
    &:hover {
        color: $primary;
    }

    &.-alt {
        color: $primary;
    }

    &.-alt:focus,
    &.-alt:hover {
        color: lighten($primary, 10);
    }
}

.footer_title {
    & {
        color: $primary;
        font-size: remify(14, 16);
        text-align: center;
    }
}

.footer_text {
    & {
        font-size: remify(11.5, 16);
        text-align: center;
    }
}

.footer_button {
    & {
        display: block;
        width: 100%;
    }
}

.footer_accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
    }

    &.is-active {
        max-height: remify(500);
        transition: max-height 0.25s;
        visibility: visible;
    }

    .footer_menu-list_container .menu-list.-sitemap {
        columns: 1;
        text-align: center;
    }
}

.footer_menu-list_container {
    .menu-list.-sitemap {
        font-size: remify(11.5, 16);
        margin-bottom: remify(16.5, 11.5);
    }

    .menu-list.-social {
        float: right;
        margin: remify(0 0 28, 20);
        position: relative;
        right: 50%;
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        .menu-list.-social {
            font-size: remify(16, 16);
            line-height: remify(28.8, 16);
        }
    }

    .menu-list.-social > .menu-list_item {
        left: 50%;
    }
}

.footer_ninja-form_container {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }

    .user-content {
        @extend .user-content.-light;
    }
}

.footer_translate-selector {
    & {
        margin: remify(0 0 20);
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        margin-left: auto;
        margin-right: auto;
        width: remify(160);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(relative-size(25, $content-padding) relative-size(20, $content-padding));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}

/* alt variant */

.footer-block.-alt {
    .footer_inner {
        background: $primary;
    }

    .footer_ninja-form_container .title {
        color: $light;
    }
}

/* alt fullbleed variant */

.footer-block.-alt.-fullbleed {
    & {
        background: $primary;
    }

    > .footer_inner {
        background: none;
    }
}
