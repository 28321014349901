// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
        @include viewport-scale(16, strip-unit($xl-break * 16), strip-unit($l-break * 16));
    }
}

.header_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(5, $content-padding) relative-size(10, $content-padding));
    }
}

.header_title {
    & {
        font-size: remify(10, 16);
    }
}

.header_logo {
    & {
        margin: 0 auto;
        padding: remify(15, 16);
        width: remify(300, 16);
    }
}

.header_toolbar {
    & {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $background;
        padding: remify(relative-size(5, $content-padding) relative-size(10, $content-padding));
    }

    > .header_inner {
        background: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
