// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-id, selector-no-qualifying-type, selector-no-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-bar {
    & {
        background: $background_alt;
        display: flex;
        flex-wrap: wrap;
        margin: remify(0 0 20, 16);
    }
}

.tribe-events-bar_text {
    & {
        font-size: remify(12, 16);
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }
}

.tribe-events-bar_input {
    & {
        font-size: remify(12, 16);
        margin-bottom: remify(10, 12);
    }
}

.tribe-events-bar_button {
    & {
        display: block;
        font-size: remify(14, 16);
        margin-bottom: 0;
        padding: remify(6.5 10, 14);
        width: 100%;
    }
}

.tribe-events-bar_toggle {
    & {
        background: $background_alt;
        border: 0;
        flex: 1 1 auto;
        font-family: $heading-font;
        font-size: remify(12, 16);
        font-weight: 700;
        margin: 0;
        padding: remify(20, 12);
        position: relative;
        text-align: left;
        text-transform: uppercase;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background-color: darken($background_alt, 5);
    }

    &::after {
        border-left: remify(6, 12) solid transparent;
        border-right: remify(6, 12) solid transparent;
        border-top: remify(6, 12) solid $dark;
        content: "";
        display: block;
        position: absolute;
        right: remify(20, 12);
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.15s;
    }

    &.tribe-bar-filters-open::after {
        transform: rotate(-180deg);
    }
}

.tribe-events-bar_views {
    & {
        background: darken($background_alt, 10);
        flex: none;
        min-width: remify(150, 16);
        padding: remify(10 20, 16);
        position: relative;
        width: 100%;
    }

    // Tribe generates these classes so I can't change them:

    .tribe-bar-views-list {
        background: inherit;
        color: $foreground;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        left: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        padding: remify(0 20 10, 14);
        position: absolute;
        right: 0;
        white-space: nowrap;
        z-index: 999;
    }

    &:not(.tribe-bar-views-open) .tribe-bar-views-option:not(.tribe-bar-active) {
        display: none;
    }

    .tribe-bar-views-option a {
        color: $foreground;
        text-decoration: none;
    }

    .tribe-bar-views-option a {
        background: left center / remify(14, 14) no-repeat;
        padding-left: remify(18, 14);
    }

    .tribe-bar-views-option.tribe-bar-views-option-month a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M30.9,6.9v22.9c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.6,0.7H3.4c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V6.9c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h2.3V2.9c0-0.8,0.3-1.5,0.8-2C7.1,0.3,7.8,0,8.6,0h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7h6.9V2.9c0-0.8,0.3-1.5,0.8-2c0.6-0.6,1.2-0.8,2-0.8h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7h2.3c0.6,0,1.2,0.2,1.6,0.7C30.6,5.7,30.9,6.2,30.9,6.9z M3.4,16.6h5.1v-5.1H3.4V16.6z M3.4,23.4h5.1v-5.7H3.4V23.4z M3.4,29.7h5.1v-5.1H3.4V29.7z M10.3,8V2.9c0-0.2-0.1-0.3-0.2-0.4C10,2.3,9.9,2.3,9.7,2.3H8.6c-0.2,0-0.3,0.1-0.4,0.2C8.1,2.6,8,2.7,8,2.9V8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2C10.2,8.3,10.3,8.2,10.3,8z M9.7,16.6h5.7v-5.1H9.7V16.6z M9.7,23.4h5.7v-5.7H9.7V23.4z M9.7,29.7h5.7v-5.1H9.7V29.7z M16.6,16.6h5.7v-5.1h-5.7V16.6z M16.6,23.4h5.7v-5.7h-5.7V23.4zM16.6,29.7h5.7v-5.1h-5.7V29.7z M24,8V2.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4V8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2C23.9,8.3,24,8.2,24,8zM23.4,16.6h5.1v-5.1h-5.1V16.6z M23.4,23.4h5.1v-5.7h-5.1V23.4z M23.4,29.7h5.1v-5.1h-5.1V29.7z'/%3E%3C/svg%3E%0A");
    }

    .tribe-bar-views-option.tribe-bar-views-option-list a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M32,6.3v19.4c0,0.8-0.3,1.5-0.8,2c-0.6,0.6-1.2,0.8-2,0.8H2.9c-0.8,0-1.5-0.3-2-0.8c-0.6-0.6-0.8-1.2-0.8-2V6.3,c0-0.8,0.3-1.5,0.8-2c0.6-0.6,1.2-0.8,2-0.8h26.3c0.8,0,1.5,0.3,2,0.8C31.7,4.8,32,5.5,32,6.3z M29.7,25.7V10.9,c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H2.9c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v14.9c0,0.2,0.1,0.3,0.2,0.4,c0.1,0.1,0.2,0.2,0.4,0.2h26.3c0.2,0,0.3-0.1,0.4-0.2C29.7,26,29.7,25.9,29.7,25.7z M6.9,13.1v1.1c0,0.2-0.1,0.3-0.2,0.4,c-0.1,0.1-0.2,0.2-0.4,0.2H5.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4,c0.1-0.1,0.2-0.2,0.4-0.2h1.1c0.2,0,0.3,0.1,0.4,0.2C6.8,12.9,6.9,13,6.9,13.1z M6.9,17.7v1.1c0,0.2-0.1,0.3-0.2,0.4,c-0.1,0.1-0.2,0.2-0.4,0.2H5.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4,c0.1-0.1,0.2-0.2,0.4-0.2h1.1c0.2,0,0.3,0.1,0.4,0.2C6.8,17.4,6.9,17.6,6.9,17.7z M6.9,22.3v1.1c0,0.2-0.1,0.3-0.2,0.4,C6.6,23.9,6.4,24,6.3,24H5.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2,h1.1c0.2,0,0.3,0.1,0.4,0.2C6.8,22,6.9,22.1,6.9,22.3z M27.4,13.1v1.1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H9.7,c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h17.1c0.2,0,0.3,0.1,0.4,0.2,C27.4,12.9,27.4,13,27.4,13.1z M27.4,17.7v1.1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H9.7c-0.2,0-0.3-0.1-0.4-0.2,c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h17.1c0.2,0,0.3,0.1,0.4,0.2,C27.4,17.4,27.4,17.6,27.4,17.7z M27.4,22.3v1.1c0,0.2-0.1,0.3-0.2,0.4C27.1,23.9,27,24,26.9,24H9.7c-0.2,0-0.3-0.1-0.4-0.2,c-0.1-0.1-0.2-0.2-0.2-0.4v-1.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h17.1c0.2,0,0.3,0.1,0.4,0.2,C27.4,22,27.4,22.1,27.4,22.3z' /%3E%3C/svg%3E%0A");
    }

    .tribe-bar-views-option.tribe-bar-views-option-day a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M30.9,6.9v22.9c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.6,0.7H3.4c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V6.9,c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h2.3V2.9c0-0.8,0.3-1.5,0.8-2C7.1,0.3,7.8,0,8.6,0h1.1c0.8,0,1.5,0.3,2,0.8,c0.6,0.6,0.8,1.2,0.8,2v1.7h6.9V2.9c0-0.8,0.3-1.5,0.8-2c0.6-0.6,1.2-0.8,2-0.8h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7,h2.3c0.6,0,1.2,0.2,1.6,0.7C30.6,5.7,30.9,6.2,30.9,6.9z M3.4,29.7h25.1V11.4H3.4V29.7z M10.3,8V2.9c0-0.2-0.1-0.3-0.2-0.4,C10,2.3,9.9,2.3,9.7,2.3H8.6c-0.2,0-0.3,0.1-0.4,0.2C8.1,2.6,8,2.7,8,2.9V8c0,0.2,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2h1.1,c0.2,0,0.3-0.1,0.4-0.2C10.2,8.3,10.3,8.2,10.3,8z M24.4,17.2l-9.1,9.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-5.1-5.1,c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4l0.8-0.8c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l3.9,3.9l7.9-7.9,c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.8,0.8c0.1,0.1,0.2,0.2,0.2,0.4S24.5,17.1,24.4,17.2z M24,8V2.9,c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4V8c0,0.2,0.1,0.3,0.2,0.4,s0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2S24,8.2,24,8z' /%3E%3C/svg%3E%0A");
    }
}

.tribe-events-bar_filters {
    & {
        display: none;
        flex: 1 1 100%;
        padding: remify(0 20, 16);
    }

    &::before,
    &::after {
        clear: both;
        content: "";
        display: block;
        height: remify(20, 16);
        width: 100%;
    }
}
