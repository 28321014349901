// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Banner
\* ------------------------------------------------------------------------ */

.banner-block {
    & {
        @include layer("banner-block");
    }

    &:not(.is-active) {
        display: none;
    }
}

.banner_inner {
    & {
        background: $warning;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }
}

.banner_icon {
    & {
        color: $light;
        font-size: remify(32, 16);
    }
}

.banner_text {
    & {
        color: $light;
        margin-bottom: 0;
    }
}

.banner_button {
    & {
        background: $warning_alt;
        border: 0;
        border-radius: 50%;
        font-size: remify(14, 16);
        margin: 0;
        padding: remify(7.5 9 5.5, 14);
    }

    &:focus,
    &:hover {
        background-color: darken($warning_alt, 5);
    }
}

.banner_user-content {
    /* stylelint-disable selector-no-type */

    a:not(.button),
    a:not(.button):focus,
    a:not(.button):hover {
        color: $light;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    /* stylelint-enable selector-no-type */
}

/* fullbleed variant */

.banner-block.-fullbleed {
    & {
        background: $warning;
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }

    > .banner_inner {
        background: none;
        padding: 0;
    }

    > .banner_inner.-fullbleed {
        max-width: none;
    }
}
