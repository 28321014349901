// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Donate Form
\* ------------------------------------------------------------------------ */

.donate-form {
    & {
        border: remify(1) solid $primary_alt;
        border-radius: remify(5, 16);
        display: flex;
        margin: remify(0 0 18, 16);
        max-width: remify(350, 16);
        overflow: hidden;
    }
}

.donate-form_label {
    & {
        background: $background_alt;
        border-radius: remify(5 0 0 5, 20);
        border-right: remify(1) solid darken($background_alt, 10);
        display: block;
        font-size: remify(16, 16);
        line-height: remify(20, 16);
        margin: 0;
        padding: remify(12.857 16 7.857, 16);
    }
}

.donate-form_input {
    & {
        border: 0;
        border-radius: 0;
        font-size: remify(16, 16);
        line-height: remify(20, 16);
        margin: 0;
    }
}

.donate-form_button {
    & {
        background: $primary;
        border-left: remify(1) solid $primary_alt;
        border-radius: 0;
        font-size: remify(16, 16);
        line-height: remify(20, 16);
        margin: 0;
    }

    &:focus,
    &:hover {
        background: lighten($primary, 10);
    }
}
