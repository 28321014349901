// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: none;
        border: 0;
        color: $light;
        font-family: $heading-font;
        font-size: remify(13.5, 16);
        margin: 0;
        padding: remify(2.5 7.5 2.5 37.5, 13.5);
        position: relative;
    }

    &::before,
    &::after {
        background: currentColor;
        bottom: 0;
        content: "";
        display: block;
        height: remify(3, 13.5);
        left: remify(7.5, 13.5);
        margin: auto;
        position: absolute;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(20, 13.5);
    }

    &.-rounded::before,
    &.-rounded::after {
        border-radius: remify(1.5, 13.5);
    }

    &.is-active::before,
    &.is-active::after {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }

    &::before {
        box-shadow: remify(0 6 0 0, 13.5) currentColor;
        top: remify(-12, 13.5);
    }

    &.is-active::before {
        box-shadow: 0 0 0 0 transparent;
        top: 0;
        transform: rotate(45deg);
    }

    &::after {
        top: remify(12, 13.5);
    }

    &.is-active::after {
        top: 0;
        transform: rotate(-45deg);
    }
}
