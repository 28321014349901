// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_inner {
    & {
        padding-bottom: remify(relative-size(24, $content-padding));
        padding-top: remify(relative-size(24, $content-padding));
    }
}

.hero_title {
    & {
        font-size: remify(26, 16);
        margin: 0;
        padding: remify(26 30 22, 26);
        text-align: left;
    }
}

.hero_menu-list_container {
    .menu-list.-quicklinks {
        font-size: remify(26, 16);
    }
}

.hero_swiper-container {
    .swiper-slide {
        border-radius: remify(7.64712);
        overflow: hidden;
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        padding-bottom: remify(relative-size(24, $content-padding));
        padding-top: remify(relative-size(24, $content-padding));
    }

    > .hero_inner {
        padding: 0;
    }
}
