// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_row {
    & > [class*="col-"] {
        padding-top: 0;
    }

    & > [class*="col-"]:not(:first-child) {
        padding-left: remify(34, 16);
    }

    & > [class*="col-"]:not(:last-child) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23C7C3BA' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: right top;
        background-repeat: repeat-y;
        background-size: remify(6);
        padding-bottom: 0;
        padding-right: remify(40);
    }
}

.footer_title {
    & {
        font-size: remify(17, 16);
        text-align: left;
    }
}

.footer_text {
    & {
        font-size: remify(14, 16);
        text-align: left;
    }
}

.footer_menu-list_container {
    .menu-list.-sitemap {
        font-size: remify(14, 16);
    }

    .menu-list.-social {
        float: none;
        right: auto;
    }

    .menu-list.-social > .menu-list_item {
        left: auto;
    }
}

.footer_ninja-form_container {
    /* stylelint-disable selector-no-type, selector-type-no-unknown */

    /* Ninja Forms wraps every field in a custom element, forcing me to style these elements directly */

    nf-fields-wrap {
        display: flex;
        width: calc(100% + #{remify(16, 16)});
    }

    nf-fields-wrap > nf-field {
        flex: 1 1 0%;
        padding-right: remify(16, 16);
    }

    nf-fields-wrap > nf-field:last-child {
        flex: 0 0 auto;
    }

    nf-fields-wrap > nf-field .input {
        margin-bottom: 0;
    }

    /* stylelint-enable */
}
