// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-image {
    & {
        display: block;
        width: 100%;
    }

    .swiper-container.-fixedheight & {
        left: 50%;
        min-width: 100%;
        position: relative;
        transform: translateX(-50%);
        width: auto;
    }
}

.swiper-caption {
    & {
        background: $dark;
        flex: 1 1 auto;
        padding: remify(($content-padding * 0.5) $content-padding);
    }

    .swiper-container.-fullbleed & .swiper-caption-inner {
        margin: 0 auto;
        max-width: remify($site-width, 16);
    }
}

.swiper-button {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        top: 0;
        transition: opacity 0.15s;
        width: remify(55, 16);
    }

    &.-prev {
        background: none;
        left: 0;
    }

    .swiper-container-rtl &.-prev {
        background: none;
        right: 0;
    }

    &.-next {
        background: none;
        right: 0;
    }

    .swiper-container-rtl &.-next {
        background: none;
        left: 0;
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        opacity: 1;
    }

    .swiper-button-icon,
    .swiper-button-icon {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        left: auto;
        position: static;
        top: auto;
    }
}

.swiper-pagination-bullet {
    & {
        background: $primary;
        height: remify(7, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(7, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(11 3, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $accent;
    }
}
