// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_inner {
    & {
        padding: remify(0 relative-size(20, $content-padding));
    }
}

.header_logo {
    & {
        padding: remify(25 0, 16);
        width: remify(355, 16);
    }
}

.header_title {
    & {
        font-size: remify(28, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding: remify(0 relative-size(20, $content-padding));
    }

    > .header_inner {
        padding-left: 0;
        padding-right: 0;
    }
}
