// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #155E78;
$primary_alt: #00445C;

$accent: #CE3345;
$accent_alt: #B40018;

$light: #FFFFFF;
$light_alt: #C7C7C7;

$dark: #000000;
$dark_alt: #383838;

$foreground: #000000;
$foreground_alt: #ABABAB;

$background: #FFFFFF;
$background_alt: #EEEAE2;

$page_background: #FFFFFF;
$page_background_alt: #EEEAE2;

$warning: #CE3345;
$warning_alt: #B40018;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Poppins", "Arial", "Helvetica", sans-serif;
$body-font: "Poppins", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1400;
$column-gap: 40;
$content-padding: 40;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    toolbar: 7,
    header: 6,
    navigation: 5,
    hero: 4,
    content: 3,
    callouts: 2,
    footer: 1,
);
