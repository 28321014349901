// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: left center;
        background-repeat: repeat-x;
        background-size: remify(6);
        border: 0;
        clear: both;
        height: remify(6);
        margin: remify(0 0 30, 16);
        opacity: 0.5;
        width: 100%;
    }
}
