// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    & {
        @extend %clearfix;
    }
}

.menu-list {
    & {
        @extend %clearfix;

        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }

    &.-mega {
        position: static;
    }
}

.menu-list_link {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 1 1 auto;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        margin: 0;
        padding: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

/* between variant */

.menu-list.-between {
    & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .menu-list_item {
        flex: 0 0 auto;
        float: none;
    }

    &::after {
        flex: 0 0 100%;
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

/* flex variant */

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 auto;
        width: 0;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* vertical reverse variant */

.menu-list.-vertical.-reverse {
    & {
        text-align: right;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier accordion variant */

.menu-list[class*="-tier"].-accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > & {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: remify(350, 16);
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* mega variant */

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        right: 0;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    > .menu-list.-tier1 {
        flex: 1 1 auto;
        min-width: 0;
    }
}

/* meta variant */

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

/* navigation variant */

.menu-list.-navigation {
    & {
        font-family: $body-font;
        font-size: remify(17, 16);
        font-weight: 400;
    }

    .menu-list_link {
        color: $light;
        padding: remify(12.5 25, 17);
        transition: background-color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: $primary_alt;
    }

    .menu-list_toggle {
        color: $light;
        font-size: 1em;
        padding: remify(12.5 25, 17);
        transition: background-color 0.15s;
    }

    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: $primary_alt;
    }

    .menu-list_container.-mega,
    .menu-list.-tier1.-accordion,
    .menu-list.-tier1.-overlay {
        background-color: $primary_alt;
    }

    .menu-list.-tier1 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: darken($primary_alt, 5);
    }

    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1 .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list.-tier1 .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: darken($primary_alt, 5);
    }

    .menu-list.-tier2.-accordion,
    .menu-list.-tier2.-overlay {
        background-color: darken($primary_alt, 10);
    }

    .menu-list.-tier2 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier2.-accordion .menu-list_link:focus,
    .menu-list.-tier2.-accordion .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_link:focus,
    .menu-list.-tier2.-overlay .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: darken(darken($primary_alt, 10), 5);
    }

    .menu-list_container.-mega .menu-list.-tier2 .menu-list_link::before {
        content: "\002D\0020\0020";
    }
}

/* pagination variant */

.menu-list.-pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}

/* quicklinks variant */

.menu-list.-quicklinks {
    & {
        background: $background;
        border-radius: remify(5, 17);
        color: $dark;
        font-family: $body-font;
        font-size: remify(17, 16);
        font-weight: 400;
        padding: remify(7.5 15, 17);
    }

    &:not(.-vertical) > .menu-list_item:not(:last-child) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23EEEAE2' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: right top;
        background-repeat: repeat-y;
        background-size: remify(6);
        padding-right: remify(6);
    }

    &.-vertical > .menu-list_item:not(:last-child) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23EEEAE2' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: left bottom;
        background-repeat: repeat-x;
        background-size: remify(6);
        padding-bottom: remify(6);
    }

    .menu-list_link {
        align-items: center;
        background: $background;
        color: $dark;
        display: flex;
        padding: remify(8, 17);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $dark;
    }

    .menu-list_icon {
        color: $primary;
        font-size: remify(34, 17);
        margin-right: remify(12, 34);
    }

    .menu-list_icon .icon_svg {
        bottom: 0;
    }
}

/* sitemap variant */

.menu-list.-sitemap {
    & {
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 400;
        line-height: remify(16, 14);
    }

    .menu-list_link {
        color: $foreground;
        padding: remify(4 0, 14);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: lighten($foreground, 10);
    }
}

/* sitemap vertical variant */

.menu-list.-sitemap.-vertical {
    & {
        column-gap: remify(20, 14);
        columns: 2;
    }

    .menu-list_item {
        break-inside: avoid;
    }
}

/* social variant */

.menu-list.-social {
    & {
        font-size: remify(20, 16);
        line-height: remify(32, 20);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(7.5, 20);
    }

    .menu-list_link {
        background: $primary;
        border-radius: remify(5, 20);
        color: $light;
        height: remify(32, 20);
        width: remify(32, 20);
    }

    .menu-list_item.-facebook > .menu-list_link {
        background-color: #155E78;
    }

    .menu-list_item.-flickr > .menu-list_link {
        background-color: #FF0084;
    }

    .menu-list_item.-instagram > .menu-list_link {
        background-color: #D10869;
    }

    .menu-list_item.-twitter > .menu-list_link {
        background-color: #19BFE5;
    }

    .menu-list_item.-youtube > .menu-list_link {
        background-color: #E02F2F;
    }
}

/* toggles variant */

.menu-list.-toggles {
    & {
        font-size: remify(20, 16);
        line-height: remify(27, 20);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(7.5, 20);
    }

    .menu-list_link {
        background: $background;
        border-radius: remify(5, 20);
        color: $primary;
        height: remify(27);
        width: remify(27, 20);
    }
}
