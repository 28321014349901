// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts_inner {
    & {
        padding-bottom: remify(relative-size(35, $content-padding));
        padding-top: remify(relative-size(35, $content-padding));
    }
}

/* fullbleed variant */

.callouts-block.-fullbleed {
    & {
        padding-bottom: remify(relative-size(35, $content-padding));
        padding-top: remify(relative-size(35, $content-padding));
    }

    > .callouts_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
