// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-id, selector-no-qualifying-type, selector-no-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-bar_input {
    & {
        margin-bottom: 0;
    }
}

.tribe-events-bar_views {
    & {
        order: 2;
        padding-bottom: remify(15, 16);
        padding-top: remify(15, 16);
    }

    .tribe-events-bar_text.-label {
        margin-bottom: remify(5, 12);
    }
}

.tribe-events-bar_filters {
    & {
        display: block !important; // override in case the user collapsed then switched to desktop
        flex: 1 1 auto;
        order: 1;
    }

    &::before,
    &::after {
        height: remify(15, 16);
    }
}
