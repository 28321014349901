// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts-block {
    & {
        @include layer("callouts");
    }
}

.callouts_inner {
    & {
        background: $background_alt;
        border-bottom: remify(relative-size(10, $content-padding)) solid $background;
        border-top: remify(relative-size(10, $content-padding)) solid $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }
}

.callouts_row {
    &.-padded {
        width: calc(100% + #{remify(relative-size(70, $column-gap))});
    }

    &.-padded > .col,
    &.-padded > [class*="col-"] {
        padding-right: remify(relative-size(70, $column-gap), 16);
    }
}

/* fullbleed variant */

.callouts-block.-fullbleed {
    & {
        background: $background_alt;
        border-bottom: remify(relative-size(10, $content-padding)) solid $background;
        border-top: remify(relative-size(10, $content-padding)) solid $background;
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }

    > .callouts_inner {
        background: none;
        border-bottom: 0;
        border-top: 0;
        padding: 0;
    }

    > .callouts_inner.-fullbleed {
        max-width: none;
    }
}
