// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_menu-list_container {
    .menu-list.-meta {
        margin: remify(0 0 20, 16);
    }
}

.article_figure {
    & {
        margin: 0;
    }
}

.article_image {
    & {
        border-radius: remify(5, 16);
        display: block;
        margin: remify(0 auto 10, 16);
        max-width: 100%;
    }
}

/* callout variant */

.article.-callout {
    .article_title {
        font-size: remify(13.5, 16);
    }

    .article_text {
        font-size: remify(11.5, 16);
    }

    .article_image {
        width: 100%;
    }
}

/* eventexcerpt variant */

.article.-eventexcerpt {
    .article_figure {
        margin-bottom: remify(20, 16);
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}

/* excerpt variant */

.article.-excerpt {
    &:not(:last-of-type) {
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_menu-list_container .menu-list.-meta {
        margin-bottom: 0;
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}
