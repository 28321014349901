// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

:root {
    &.is-medium {
        font-size: 1.25em;
    }

    &.is-big {
        font-size: 1.5em;
    }
}

html,
body {
    & {
        background: $page_background;
        height: 100%;
        width: 100%;
    }
}

.page_container {
    & {
        min-height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
