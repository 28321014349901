// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Size Toggle
\* ------------------------------------------------------------------------ */

.size-toggle {
    & {
        background: none;
        border: 0;
        color: $primary;
        font-family: $heading-font;
        font-size: remify(13, 16);
        margin: 0;
        padding: 0;
    }
}

.size-toggle_icon {
    & {
        font-size: remify(25, 13);
        margin: remify(0 0 0 8, 25);
        transform: translateY(#{remify(2, 25)});
    }
}
