// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */

    // links

    a:not(.button) {
        color: $accent;
        text-decoration: underline;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: lighten($accent, 10);
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $accent;
        font-family: $heading-font;
        font-weight: 700;
    }

    h1,
    h2 {
        font-size: remify(22.5, 16);
        line-height: remify(27, 22.5);
        margin: remify(0 0 10, 22.5);
    }

    h2 {
        color: $primary;
    }

    h3,
    h4 {
        font-size: remify(17.5, 16);
        line-height: remify(21, 17.5);
        margin: remify(0 0 15.5, 17.5);
    }

    h4 {
        color: $primary;
    }

    h5 {
        font-size: remify(14, 16);
        line-height: remify(18, 14);
        margin: remify(0 0 1, 14);
    }

    h6 {
        color: $primary;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(20, 16);
        margin: remify(0 0 20, 16);
        text-align: center;
    }

    // text

    p,
    ol,
    ul,
    table {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        line-height: remify(18, 14);
        margin: remify(0 0 18, 14);
    }

    p .button,
    ol .button,
    ul .button,
    table .button {
        font-size: remify(14.5, 14);
    }

    ol,
    ul {
        padding-left: remify(24, 14);
    }

    ol li,
    ul li {
        margin-bottom: remify(7, 14);
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid darken($background_alt, 10);
        padding: remify(6 8, 12);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td::before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $dark_alt;
        border-color: lighten($dark_alt, 10);
        color: $light;
        font-size: remify(16, 12);
        font-weight: 400;
        padding: remify(6 8, 16);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_alt;
    }

    // scrolling responisve tables

    @media screen and (max-width: $xs-break - (1 / 16)) {
        table {
            display: block;
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($foreground, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M23.9,16c0-2.1-0.8-4.2-2.3-5.7c-3.1-3-8.1-3.1-11.2,0l0,0C8.9,11.8,8,13.8,8.1,16c-0.1,2.1,0.7,4.2,2.3,5.7 c3.1,3.1,8.1,3.1,11.2,0l0,0C23.1,20.2,24,18.1,23.9,16z' /%3E%3C/svg%3E%0A");
        background-position: left center;
        background-repeat: space;
        background-size: remify(6);
        border: 0;
        clear: both;
        height: remify(6);
        margin: remify(0 0 30, 16);
        opacity: 0.5;
        width: 100%;
    }

    // images

    img {
        border-radius: remify(5);
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft,
    .alignright,
    .aligncenter {
        display: block;
        margin: remify(0 auto 20);
    }

    /* stylelint-enable */
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .user-content {
        /* stylelint-disable selector-no-qualifying-type, selector-no-type */

        h1,
        h2 {
            font-size: remify(28, 16);
        }

        h3,
        h4 {
            font-size: remify(19, 16);
        }

        h5 {
            font-size: remify(16, 16);
        }

        h6 {
            font-size: remify(20, 16);
            text-align: left;
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(16, 16);
        }

        p .button,
        ol .button,
        ul .button,
        table .button {
            font-size: remify(18, 16);
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote::before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }

        // alignment

        .alignleft {
            float: left;
            margin-right: remify(25);
            max-width: (100% / 3);
        }

        .alignright {
            float: right;
            margin-left: remify(25);
            max-width: (100% / 3);
        }

        /* stylelint-enable */
    }
}

/* light variant */

.user-content.-light {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }

    /* stylelint-enable */
}
