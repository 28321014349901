// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        display: block;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        line-height: remify(18, 14);
        margin: remify(0 0 18, 14);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .text {
        & {
            font-size: remify(16, 16);
        }
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

/* list variant */

.text.-list {
    & {
        list-style: disc;
        padding: remify(0 0 0 20, 14);
    }
}

/* table variant */

.text.-table {
    & {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    .text_header {
        display: table-header-group;
    }

    .text_body {
        display: table-row-group;
    }

    .text_footer {
        display: table-footer-group;
    }

    .text_row {
        display: table-row;
    }

    .text_cell {
        display: table-cell;
        padding: remify(7 14, 14);
        vertical-align: top;
    }

    .text_cell.-title {
        color: $primary;
        font-family: $heading-font;
        font-weight: 700;
        text-transform: uppercase;
    }

    .text_row:nth-child(odd) > .text_cell:not(.-title) {
        background: $background_alt;
    }
}
