// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Custom

.swiper-caption {
    & {
        background: transparentize($dark, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
    }
}

.swiper-pagination-bullet {
    & {
        height: remify(14, 16);
        width: remify(14, 16);
    }
}
