// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    & {
        padding-bottom: remify(relative-size(35, $content-padding));
        padding-top: remify(relative-size(35, $content-padding));
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        padding-bottom: remify(relative-size(35, $content-padding));
        padding-top: remify(relative-size(35, $content-padding));
    }

    > .content_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
