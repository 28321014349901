// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout_image {
    & {
        display: block;
        margin: remify(0 auto 35, 16);
        max-width: 100%;
    }
}
