// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(relative-size(20, $content-padding) relative-size(25, $content-padding));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}

/* alt variant */

.content-block.-alt {
    .content_inner {
        background: $primary;
    }
}

/* alt fullbleed variant */

.content-block.-alt.-fullbleed {
    & {
        background: $primary;
    }

    .content_inner {
        background: none;
    }
}
