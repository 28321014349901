// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $primary_alt;
        border: 0;
        border-radius: remify(5, 14.5);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-family: $heading-font;
        font-size: remify(14.5, 16);
        font-weight: 700;
        line-height: remify(20, 14.5);
        margin: remify(0 0 7.25, 14.5);
        outline: 0;
        padding: remify(8 29 5.5, 14.5);
        text-decoration: none;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background: $primary;
    }
}

/* Desktop variant */

@media screen and (min-width: $xs-break) {
    .button {
        font-size: remify(18, 16);
    }
}

/* alt variant */

.button.-alt {
    & {
        background-color: $accent;
    }

    &:focus,
    &:hover {
        background-color: lighten($accent, 10);
    }
}

/* bare variant */

.button.-bare {
    & {
        background: none;
        color: $primary;
        font-size: remify(20, 16);
        padding: remify(2 0, 20);
    }
}

/* cta variant */

.button.-cta {
    & {
        background: $accent;
        border-radius: remify(5, 17);
        font-size: remify(17, 16);
        font-weight: 400;
        margin-bottom: remify(12, 17);
        padding: remify(13 25 10.5, 17);
    }

    &.-hasicon {
        align-items: center;
        display: inline-flex;
    }

    &:focus,
    &:hover {
        background: lighten($accent, 10);
    }

    .button_icon {
        font-size: remify(24, 17);
        margin-right: remify(16, 24);
    }

    .button_icon .icon_svg {
        bottom: auto;
    }
}

/* cta Desktop variant */

@media screen and (min-width: $xs-break) {
    .button.-cta {
        font-size: remify(26, 16);
    }
}
