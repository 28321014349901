// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Notices
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-id, selector-no-qualifying-type, selector-no-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-notices {
    & {
        background: $warning;
        border: remify(1) solid darken($warning, 10);
        border-radius: remify(5, 16);
        margin: remify(0 0 20, 16);
        padding: remify(10 20, 16);
    }

    ul {
        color: $light;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
