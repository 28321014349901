// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts_inner {
    & {
        border-bottom-width: remify(relative-size(15, $content-padding));
        border-top-width: remify(relative-size(15, $content-padding));
        padding-left: remify(relative-size(125, $content-padding));
        padding-right: remify(relative-size(125, $content-padding));
    }
}

/* fullbleed variant */

.callouts-block.-fullbleed {
    & {
        border-bottom-width: remify(relative-size(15, $content-padding));
        border-top-width: remify(relative-size(15, $content-padding));
    }

    > .callouts_inner {
        border-bottom: 0;
        border-top: 0;
        padding-left: remify(relative-size(125, $content-padding));
        padding-right: remify(relative-size(125, $content-padding));
    }
}
