// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_image {
    & {
        border-radius: remify(7.6472, 16);
        margin-bottom: remify(14, 16);
    }
}

/* callout variant */

.article.-callout {
    .article_title {
        font-size: remify(17, 16);
    }

    .article_text {
        font-size: remify(14, 16);
    }
}

/* eventexcerpt variant */

.article.-eventexcerpt {
    .article_figure {
        margin-bottom: 0;
    }
}
